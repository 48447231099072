import React, {useState, useEffect} from "react";

import "./ThemePicker.scss"
import {sentenceCase} from "change-case";
import {THEMES} from "../../../constants";
import Text from "../../atoms/Text/Text";

function setUITheme(theme) {
    const themeWrapperEl = document.getElementById('theme-wrapper')
    themeWrapperEl.classList = `theme theme-${theme}`

    const bodyEl = document.getElementsByTagName('body')[0]
    bodyEl.classList = `theme-${theme}`
}

function getTheme() {
    const bodyEl = document.getElementsByTagName('body')[0]
    return bodyEl.classList.length ? bodyEl.classList[0].split("-")[1] : ""
}

const ThemePicker = (props) => {
    const { size = 66, labels = true } = props
    const [theme, setTheme] = useState("");

    useEffect(() => {
        const to = setTimeout(() => {
            setTheme(getTheme())
            clearTimeout(to)
        }, 1)
    }, []);

    const themeCircleColors = ['background', 'cat1', 'cat2', 'cat3']
    const styles = {
        themePicker: { minWidth: labels ? Object.values(THEMES).length * size + (Object.values(THEMES).length - 1) * size / 2.5 : null },
        circle: { width: size, height: size, borderRadius: size / 2},
        slice: { width: size / themeCircleColors.length }
    }
    const themes = Object.values(THEMES).map(t => {
        return (
            <div
                className={`theme-circle ${t} ${t === theme ? 'active' : ''}`}
                onClick={() => {
                    setUITheme(t)
                    setTheme(t)
                }}
                key={t}
            >
                <div className="circle-wrapper" style={styles.circle}>
                    { themeCircleColors.map(c => <div className={`theme-slice ${c}`} key={c} style={styles.slice} />) }
                </div>

                { labels && (<Text>{ sentenceCase(t) }</Text>)}
            </div>
        )
    })
    return (
        <div
            className="theme-picker"
            style={styles.themePicker}
        >
            { themes }
        </div>
    );
};

export default ThemePicker;
