import React, { useEffect } from "react";
import "./Timeline.scss";

import { useReactFlow } from "reactflow"
import TimelineCanvas from "../../molecules/TimelineCanvas/TimelineCanvas";
import peopleDataUtil from "../../../utils/peopleDataUtil";
import urlUtil from "../../../utils/urlUtil";
import generalUtil from "../../../utils/generalUtil";
import {Helmet} from "react-helmet";
import metaUtil from "../../../utils/metaUtil";
import Page from "../../atoms/Page/Page";
import TimelineFilter from "../../molecules/TimelineFilter/TimelineFilter";
import timelineUtil from "../../../utils/timelineUtil";
import episodesDataUtil from "../../../utils/episodesDataUtil";
import {TIMELINE_SCROLL_SLUG, TIMELINE_SIZES} from "../../../constants";

function Timeline() {
    const reactFlowInstance = useReactFlow();
    const isExitingPage = !urlUtil.getCurrentURL().includes('/timeline')
    const { scroll, bubble, filter, view } = isExitingPage ? urlUtil.getURLParams(generalUtil.getExplorationPath()[0]) : urlUtil.getURLParams()

    useEffect(() => {
        const { viewportInitialized } = reactFlowInstance
        if (!viewportInitialized) return

        if (scroll) {
            const slug = scroll.toLowerCase().replaceAll(" ", "-")
            const node = reactFlowInstance.getNode(slug)
            let zoom = reactFlowInstance.getZoom()
            let position = timelineUtil.calcViewport(node, zoom)

            // when click on a filter
            // then scroll into view to see the artist
            if (filter && !bubble) {
                const timeout = setTimeout(() => {
                    zoom = Math.min(zoom, 1)
                    position = timelineUtil.calcViewport(node, zoom)
                    reactFlowInstance.setViewport(position, { duration: 500 })
                    clearTimeout(timeout)
                }, 500)
                return
            }

            // when click on pill
            // then scroll into view to see the bubble
            if (bubble) {
                zoom = Math.min(Math.max(zoom, 1), 1.5)
                position = timelineUtil.calcViewport(node, zoom)
                position.y = -node.position.y * zoom + 150
                reactFlowInstance.setViewport(position, { duration: 500 })
                return
            }

            reactFlowInstance.setViewport(position, { duration: 600 })
            return
        } else {
            const prevRoute = generalUtil.getExplorationPath()[0]
            if (prevRoute && prevRoute.href.includes(view)) return

            // should maybe move to episode table?
            const slug = TIMELINE_SCROLL_SLUG[view]
            const node = reactFlowInstance.getNode(slug)
            let zoom = reactFlowInstance.getZoom()
            let position = timelineUtil.calcViewport(node, zoom)
            reactFlowInstance.setViewport(position, { duration: 0 })
        }
    }, [scroll, bubble, filter, view, reactFlowInstance.viewportInitialized])

    let timelineData
    timelineData = peopleDataUtil.sortByLVL(peopleDataUtil.getForTimeline(view))
    if (filter) timelineData = peopleDataUtil.sortBy({ episode: view, [timelineUtil.getFilterType(filter)]: filter })

    const ep = episodesDataUtil.getEpByParam(view)

    return (
        <Page classes="timeline-page" scrollable={false}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ ep.title }</title>
                <meta name="description" content={  ep.description } />
                <meta name="keywords" content={ ep.keywords } />
                <meta name="og:image" content={ metaUtil.getOGImage(ep.ogImage) } />
            </Helmet>

            <TimelineFilter
                episode={ep}
                data={timelineData}
            />

            <TimelineCanvas
                start={TIMELINE_SIZES[view]? TIMELINE_SIZES[view].start : TIMELINE_SIZES.art.start}
                end={TIMELINE_SIZES[view] ? TIMELINE_SIZES[view].end : TIMELINE_SIZES.art.end}
                data={timelineData}
            />
        </Page>
    );
}

export default generalUtil.withNavParams(Timeline)