import urlUtil from "./utils/urlUtil";

export const ENVS = {
    PRODUCTION: "PRODUCTION",
    STAGING: "STAGING"
}

export const THEMES = {
    LIGHT: 'light',
    DARK: 'dark',
    JUNGLE: 'jungle',
    BALEARIC: 'balearic',
    RAVE: 'rave',
    HIPPIE: 'hippie',
    PASTEL: 'pastel',
}

export const TIMELINE_SIZES = {
    art: { start: 1325, end: 2025},
    science: { start: -700, end: 2025},
    composers: { start: 1450, end: 2025},
    serbs: { start: 1750, end: 2025},
    germans: { start: -700, end: 2025},
    italians: { start: -700, end: 2025},
}
export const TIMELINE_SCROLL_SLUG = {
    art: "caravaggio",
    science: "isaac-newton",
    composers: "wolfgang-amadeus-mozart",
    serbs: "nikola-tesla",
    germans: "ludwig-van-beethoven",
    italians: "leonardo-da-vinci",
    french: "claude-monet",
}


export const EVENT_LINK_TYPES = {
    ARTWORK: 'artwork',
    BIO: 'bio',
}

export const ISO2 = {
    American: 'us',
    Argentinean: 'ar',
    Austrian: 'at',
    Belarusian: 'by',
    Belgian: 'be',
    Brazilian: 'br',
    British: 'gb',
    Canadian: 'ca',
    Chinese: 'cn',
    Colombian: 'co',
    Cuban: 'cu',
    Czech: 'cz',
    Danish: 'dk',
    Dutch: 'nl',
    French: 'fr',
    German: 'de',
    Greek: 'gr',
    Hungarian: 'hu',
    Indian: 'in',
    Irish: 'ie',
    Italian: 'it',
    Japanese: 'jp',
    Korean: 'kr',
    Latvian: 'lv',
    Mexican: 'mx',
    Norwegian: 'no',
    Polish: 'pl',
    Portugese: 'pt',
    Qatari: 'qa',
    Romanian: 'ro',
    Russian: 'ru',
    Serbian: 'rs',
    Spanish: 'es',
    Swedish: 'se',
    Swiss: 'ch',
    Ukrainian: 'ua',
}

export const EVENTS = {
    NAVIGATE: "NAVIGATE",
}

export const WALLETS = {
    BTC: {
        coin: "Bitcoin",
        wallet: "38Jk3469aeiu5FjwwaTR3gz2ydjEik1nEG",
    },
    ETH: {
        coin: "Ethereum",
        wallet: "0x95c8a5f473A472b2B74cBf81e4366780DB1089dB"
    },
    MATIC: {
        coin: "Polygon",
        wallet: "0x95c8a5f473A472b2B74cBf81e4366780DB1089dB"
    },
}