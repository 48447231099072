import React from "react";
import { useStore } from "reactflow";

import "./TimelineGrid.scss"
import Text from "../../atoms/Text/Text";
import timelineUtil from "../../../utils/timelineUtil";

function getGridProps(zoom) {
    if (zoom > 2.5) return { step: 20, helperEvery: 5 }
    if (zoom > 2) return { step: 25, helperEvery: 5 }
    if (zoom > 0.75) return { step: 50, helperEvery: 10 }
    return { step: 100, helperEvery: 20 }
}

export const TimelineLines = (props) => {
    const { x, zoom } = useStore(timelineUtil.getViewport)

    const { timelineWidth, timelineHeight, start, end } = props
    const scale = 2 * zoom
    const { step, helperEvery } = getGridProps(zoom)

    const gridWidth = timelineWidth * zoom
    const gridLeft = x

    const startYearDisplay = start + step - (start % step)
    const endYearDisplay = end - (end % step)
    const timelinePaddingLeft = (startYearDisplay - start) * scale
    const years = []
    const lines = []

    let l = 0
    for (let i = startYearDisplay; i <= endYearDisplay; i= i + step) {
        years.push(<div key={i} className="year-wrapper" style={{ left: timelinePaddingLeft + l * step * scale - 14 }}><Text font weight={500}>{i}</Text></div>)
        lines.push(<div key={i} className="vertical-line main" style={{ height: "100%", left: timelinePaddingLeft + l * step * scale - 1 }}></div>)
        l++
    }
    const helperLines = []
    let year = start
    while (year <= end) {
        if ((year % helperEvery === 0) && (year % step !== 0)) {
            helperLines.push(<div key={year} className="vertical-line helper" style={{ height: timelineHeight, left: (year - start) * scale - 1 }}></div>)
        }
        year++
    }
    lines.push(<div key="vertical" className="vertical-line current" style={{ height: timelineHeight, left: (new Date().getFullYear() - start) * scale }}></div>)

    return (
        <div className="timeline-grid">
            <div className="timeline-background" style={{ height: "100%", width: gridWidth, left: gridLeft }}>
                {lines}
                {helperLines}
            </div>
            <div className="timeline-years bottom" style={{ width: gridWidth, left: gridLeft }}>
                {years}
            </div>

        </div>
    )
};

export function TimelineYears(props) {
    const { x, zoom } = useStore(timelineUtil.getViewport)

    const { timelineWidth, start, end } = props
    const scale = 2 * zoom
    const { step } = getGridProps(zoom)

    const gridWidth = timelineWidth * zoom
    const gridLeft = x

    const startYearDisplay = start + step - (start % step)
    const endYearDisplay = end - (end % step)
    const timelinePaddingLeft = (startYearDisplay - start) * scale
    const years = []

    let l = 0
    for (let i = startYearDisplay; i <= endYearDisplay; i= i + step) {
        years.push(<div key={i} className="year-wrapper" style={{ left: timelinePaddingLeft + l * step * scale - 14 }}><Text font weight={500}>{i >= 0 ? i : `${-i}     BC`}</Text></div>)
        l++
    }

    return (
        <div className="timeline-grid years">
            <div className="timeline-years bottom" style={{ width: gridWidth, left: gridLeft }}>
                {years}
            </div>
        </div>
    )
}