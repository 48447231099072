import React, { Component } from 'react';
import "./BioYear.scss"
import generalUtil from "../../../utils/generalUtil";
import urlUtil from "../../../utils/urlUtil";
import peopleDataUtil from "../../../utils/peopleDataUtil";
import {capitalCase} from "change-case";
import {motion} from "framer-motion";

class BioYear extends Component {
    getStyles(catData, numberOfCategories) {
        let top, right, bottom, left;
        const size = 24 + catData.value * 4

        if (numberOfCategories === 2) {
            if (catData.position === 1) {
                right = 14;
                top = 2 - catData.value * 2
            }
            if (catData.position === 2) {
                left = 14;
                top = 2 - catData.value * 2
            }
        }

        if (numberOfCategories === 3) {
            if (catData.position === 1) {
                left = 8 - catData.value * 2;
                top = 2 - catData.value * 2
            }
            if (catData.position === 2) {
                right = 14;
                top = 16 - catData.value * 2
            }
            if (catData.position === 3) {
                left = 14;
                top = 16 - catData.value * 2
            }
        }

        if (numberOfCategories === 4) {
            if (catData.position === 1) {
                right = 14;
                top = 2 - catData.value * 2
            }
            if (catData.position === 2) {
                left = 14;
                top = 2 - catData.value * 2
            }
            if (catData.position === 3) {
                left = 14;
                top = 16 - catData.value * 2
            }
            if (catData.position === 4) {
                right = 14;
                top = 16 - catData.value * 2
            }
        }

        if (numberOfCategories === 5) {
            if (catData.position === 1) {
                left = (48 - size) / 2;
                bottom = 14 + 6
            }
            if (catData.position === 2) {
                left = 14 + 6;
                bottom = (48 - size) / 2;
            }
            if (catData.position === 3) {
                left = 14 + 2;
                top = 14 + 20 - size / 2
            }
            if (catData.position === 4) {
                right = 14 + 2;
                top = 14 + 20 - size / 2
            }
            if (catData.position === 5) {
                right = 14 + 6;
                bottom = (48 - size) / 2;
            }
        }

        return {
            height: size,
            width: size,
            left,
            top,
            right,
            bottom,
        }
    }
    render () {
        const { cat1, cat2, cat3, cat4, cat5, cat6, cat7, year, navigateTo, position } = this.props
        const isExitingPage = !urlUtil.getCurrentURL().includes('/bio')
        const { slug, year: routeYear, filter } = isExitingPage ? urlUtil.getURLParams(generalUtil.getExplorationPath()[0]) : urlUtil.getURLParams()

        const personData = peopleDataUtil.getBySlug(slug)
        const filterCategory = filter && (personData.categories[capitalCase(filter)] || personData.categories[filter])

        const numberOfCategories = !!cat1 + !!cat2 + !!cat3 + !!cat4 + !!cat5 + !!cat6 + !!cat7

        if (!numberOfCategories) return;

        const catData = []
        let pos = 1;
        [cat1, cat2, cat3, cat4, cat5, cat6, cat7].forEach((c, i) => {
            if (c) {
                 catData.push({ cat: i + 1, value: c, position: pos })
                 pos++
            }
        })

        let route = urlUtil.createURL('bio', { slug, year })

        const catCircles = catData.map(cat => (
            <div
                key={cat.cat}
                className={`circle cat-${cat.cat}`}
                style={this.getStyles(cat, numberOfCategories)}
            />
        ))

        let highlightClass = ''
        if (filter) {
            if (year == routeYear) highlightClass = `filter-${ filterCategory }`
            if (!routeYear) highlightClass = `filter-${ filterCategory }`
        } else {
            if (year == routeYear) highlightClass = `active`
        }

        const classes = [
            'bio-year',
            highlightClass
        ].join(" ")

        return (
            <div className={classes} onClick={() => navigateTo(route)} id={`${slug}-${year}`}>
                <motion.div
                    key={`${slug}-${year}`}
                    className={`cats-${numberOfCategories}`}
                    initial={{ opacity: 0, scale: 0.2 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ ease: "easeIn", duration: 0.5, delay: position / 120 }}
                >
                    {catCircles}
                </motion.div>
            </div>
        )
    }
}

BioYear.propTypes = {
    // cat1,
    // cat2,
    // cat3,
    // cat4,
};

export default generalUtil.withNavParams(BioYear)