import urlUtil from "./urlUtil";
import episodesDataUtil from "./episodesDataUtil";
import DB from "../data/DB.json"

const RANDOM_QUESTIONS = DB.RANDOM_QUESTIONS.filter(rq => !!rq.url)

const randomQuestionsUtil = {
    getQuestions: (number = 5) => {
        const epIds = episodesDataUtil.getReleasedEpisodeIds()
        return RANDOM_QUESTIONS
            .filter(rl => epIds.includes(rl.epId))
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
            .slice(0, number)
    }
}

export default randomQuestionsUtil