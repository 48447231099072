import React, { Component } from 'react';
import "./YearsGrid.scss"
import Text from "../../atoms/Text/Text";
import BioYear from "../../atoms/BioYear/BioYear";
import urlUtil from "../../../utils/urlUtil";
import peopleDataUtil from "../../../utils/peopleDataUtil";
import generalUtil from "../../../utils/generalUtil";

class YearsGrid extends Component {
    renderYearLine(year, birth, death) {
        const isExitingPage = !urlUtil.getCurrentURL().includes('/bio')
        const { slug, filter } = isExitingPage ? urlUtil.getURLParams(generalUtil.getExplorationPath()[0]) : urlUtil.getURLParams()
        const queryYear = isExitingPage ? urlUtil.getURLParams(generalUtil.getExplorationPath()[0]).year : urlUtil.getURLParams().year
        const { data, step = 5 } = this.props
        const bioData = peopleDataUtil.getBySlug(slug)
        const dots = []

        for (let i = year; i < year + step; i++) {
            const dotClasses = [
                "dot-wrapper",
                (i < birth || i > death) ? "invisible" : "",
                (queryYear == i) ? "active" : "",
            ].join(" ")

            let shouldShowDecade = Number(queryYear) !== i && (i - birth) % 10 == 0 && i !== birth
            const hasCategory = !!(data[i] && data[i][bioData.categories[filter]])
            shouldShowDecade = shouldShowDecade && !hasCategory

            if ((i === birth - 1) || (i === death + 1 && death % 5 !== 0)) {
                dots.push((
                    <div key={i} className="bd-year">
                        <Text type="small" align="center">{ i === birth - 1 ? birth : death }</Text>
                    </div>
                ))
            } else {
                dots.push((
                    <div key={i} className={dotClasses}>
                        <div className="line" />
                        <BioYear
                            { ...data[i]}
                            year={i}
                            position={i - birth}
                        />
                        { shouldShowDecade ? <Text type="small">{ i - birth}</Text> : <div className="dot" /> }
                    </div>
                ))
            }
        }

        const lineWidth = step * 60

        return (
            <div className="year-line" key={year}>
                <div className="text-wrapper">
                    { year >= birth && <Text type="small">{year}</Text> }
                </div>

                <div className="line-dots" style={{ width: lineWidth }}>
                    {dots}
                </div>
            </div>
        )
    }

    render () {
        const { birth, death, step = 5 } = this.props

        const startYear = birth - birth % step
        const rows = [];
        for (let i = startYear; i <= death; i = i + step) {
            rows.push(this.renderYearLine(i, birth, death));
        }

        const classes = [
            'years-grid',
        ].join(' ')

        return (
            <div className={classes} id="years-grid">
                <div>
                    {rows}
                </div>
            </div>
        )
    }
}

YearsGrid.propTypes = {
    // birth
    // death
    // data
};

export default YearsGrid