import React from "react";

import "./MonsterHead.scss"
import {MONSTERS} from "../../../utils/monsterImagesUtil";
import {camelCase} from "change-case";

const MonsterHead = (props) => {
    const { slug, size = 100, style, color = "card" } = props
    const monsterImg = MONSTERS[camelCase(slug)]
    const scale = 1.5
    const containerStyles = {
        width: size,
        height: size,
        borderRadius: size / 2,
        ...style,
    }
    const imageStyles = {
        width: size * scale,
        height: size * scale,
        top: 0,
        left: (1 - scale) * size / 2,
    }

    return (
        <div
            className={`monster-head ${color}`}
            style={containerStyles}
        >
            <img
                src={monsterImg}
                style={imageStyles}
                className="monster-image"
            />
        </div>
    );
};

export default MonsterHead;
