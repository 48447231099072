import React from 'react';
import "./SideMenu.scss"
import {Link} from "react-router-dom";
import SVGIcon from "../../atoms/SVGIcon/SVGIcon";
import urlUtil from "../../../utils/urlUtil";
import {AnimatePresence, motion} from "framer-motion";
import Text from "../../atoms/Text/Text";
import peopleDataUtil from "../../../utils/peopleDataUtil";
import generalUtil from "../../../utils/generalUtil";
import MonsterHead from "../../atoms/MonsterHead/MonsterHead";
import episodesDataUtil from "../../../utils/episodesDataUtil";
import ThemePicker from "../ThemePicker/ThemePicker";

function SideMenu(props) {
    const {toggleMenu, isMenuOpen, navigateTo} = props
    const {slug} = urlUtil.getURLParams()
    let name = slug ? peopleDataUtil.getBySlug(slug).name : null

    function clickLink(url) {
        if (generalUtil.isLeavingPage(url)) {
            navigateTo(url)
        }

        toggleMenu()
    }

    const releasedEpisodes = episodesDataUtil.getReleased()
    const seasons = [...new Set(releasedEpisodes.map(ep => `s0${ep.sID}`))].reverse()

    const menuLinks = {}

    seasons.forEach(s => {
        const links = releasedEpisodes
            .filter(ep => ep.id.includes(s))
            .map(ep => ({
                copy: ep.name,
                url: urlUtil.createURL(ep.page, {slug: ep.param, view: ep.param}),
                slug: ep.param,
                date: ep.rDate
            }))
            .sort((a,b) => new Date(a.date) - new Date(b.date));

        menuLinks[s] = links
    })

    return (
        <AnimatePresence>
            {isMenuOpen && (
                <motion.div
                    className="side-menu"
                    initial={{right: -250, opacity: 0}}
                    animate={{right: 0, opacity: 1}}
                    exit={{right: -250, opacity: 0}}
                    transition={{ease: "easeInOut", duration: 0.3}}
                >
                    <div
                        onClick={() => toggleMenu()}
                        className="close-btn"
                    >
                        <SVGIcon color="link" name="close"/>

                    </div>

                    <div className="links-wrapper">
                        <div className="top-link">
                            <Link
                                onClick={() => toggleMenu()}
                                to="/"
                                className="side-link"
                            >
                                <Text type="h1" weight={300} color="link">Home</Text>
                            </Link>
                        </div>

                        <hr/>

                        {seasons.map(s => {
                            return [
                                ...menuLinks[s].map(l => (
                                    <div
                                        onClick={() => clickLink(l.url)}
                                        to={l.url}
                                        className="side-link"
                                        key={l.copy}
                                    >
                                        <MonsterHead slug={l.slug} size={28}/>
                                        <Text type="h1" weight={300} color="link">{l.copy}</Text>
                                    </div>
                                )),
                                <hr />
                            ]
                        })}

                        <ThemePicker size={33} labels={false}/>
                    </div>
                </motion.div>
            )}

            {isMenuOpen && (
                <motion.div
                    onClick={() => toggleMenu()}
                    key="backdrop"
                    className="menu-backdrop"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{ease: "easeInOut", duration: 0.3}}
                />
            )}
        </AnimatePresence>
    )
}

SideMenu.propTypes = {};

export default generalUtil.withNavParams(SideMenu)