import React, { Component } from 'react';
import Text from "../../atoms/Text/Text";
import "./TopNav.scss"
import {Link} from "react-router-dom";
import SVGIcon from "../../atoms/SVGIcon/SVGIcon";
import urlUtil from "../../../utils/urlUtil";
import {AnimatePresence, motion} from "framer-motion";
import peopleDataUtil from "../../../utils/peopleDataUtil";
import SideMenu from "../SideMenu/SideMenu";
import MonsterHead from "../../atoms/MonsterHead/MonsterHead";
import episodesDataUtil from "../../../utils/episodesDataUtil";

async function share(shareData) {
    try {
        await navigator.share(shareData);
    } catch (err) {
        console.log(err)
    }
}

class TopNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
    }
    render () {
        const { header, image, slug, subtitleLink, subtitle, noShare } = this.props

        let img = !image && slug
            ? <MonsterHead slug={slug} size={62} />
            : <img src={image} className="image" />

        const episode = episodesDataUtil.getEpByParam(slug)

        const shareData = {
            url: urlUtil.getCurrentURL(),
            text: episode.description,
            title: episode.title,
        }


        let subtitleEl
        if (subtitleLink) {
            subtitleEl = <Link to={subtitleLink}><Text type="link">{ subtitle }</Text></Link>
        } else {
            subtitleEl = <Text>{ subtitle }</Text>
        }

        return (
            <div className="top-nav">
                <div className="header-wrapper">
                    { img }
                    <div className="text-wrapper">
                        <Text type="h1">{ header }</Text>
                        { subtitleEl }
                    </div>
                </div>

                <div className="nav-btns">
                    { !noShare && navigator.share && (
                        <div
                            className="share-btn"
                            onClick={async () => await share(shareData)}
                        >
                            <SVGIcon
                                color="link"
                                name="share"
                                size={20}
                            />
                        </div>
                    )}

                    <div onClick={() => this.setState({ isMenuOpen: true })}>
                        <SVGIcon color="link" name="hamburger" />
                    </div>
                </div>

                <SideMenu
                    isMenuOpen={this.state.isMenuOpen}
                    toggleMenu={() => this.setState({ isMenuOpen: !this.state.isMenuOpen })}
                />
            </div>
        )
    }
}

TopNav.propTypes = {
    // header
    // image
    // subtitle
    // subtitleLink
};

export default TopNav