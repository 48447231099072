import React from "react";

import "./NewestEpisode.scss"
import {Link} from "react-router-dom";
import Text from "../../atoms/Text/Text";
import MonsterCircle from "../../atoms/MonsterCircle/MonsterCircle";
import SVGIcon from "../../atoms/SVGIcon/SVGIcon";

const NewestEpisode = (props) => {
    const { episode, clickable = true } = props
    const colors = ["cat1", "cat2", "cat3", "cat4", "cat5", "cat6", "cat7"]
    const color = colors[Math.floor(Math.random() * colors.length)]

    const classes = [
        "newest-episode",
        color,
    ].join(" ")
    return (
        <Link
            key={ episode.id }
            className={classes}
            to={ clickable && episode.url }
        >

            <div className="ep-content">
                <div className="half">
                    <Text type="h1">{ episode.name }</Text>
                    <Text>{ episode.description }</Text>
                </div>

                <div className="half image">
                    <MonsterCircle src={episode.imageLink} size={80} />
                    { clickable && (
                        <SVGIcon name="arrow-right" size={30} />
                    )}
                </div>
            </div>
        </Link>
    )
};

export default NewestEpisode;
