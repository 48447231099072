import React from 'react';
import "./TimelinePill.scss";
import Text from "../Text/Text";
import {Link} from "react-router-dom";
import urlUtil from "../../../utils/urlUtil";
import generalUtil from "../../../utils/generalUtil";
import SVGIcon from "../SVGIcon/SVGIcon";
import {useStore} from "reactflow";
import MonsterHead from "../MonsterHead/MonsterHead";
import episodesDataUtil from "../../../utils/episodesDataUtil";

const BUBBLE_WIDTH = 250

function TimelinePill (props) {
    const { data, navigateTo } = props
    const { person, timelineWidth, pillHeight = 19 } = data
    const { bubble, filter, view } = urlUtil.getURLParams()
    const zoom = useStore(s => s.transform[2])
    // const zoomLvl = timelineUtil.getZoomLvl(zoom)
    const zoomLvl = ""
    const { left, width, name, lvl, isAlive } = person;

    let isFiltered = false
    if (!!filter && [
        ...person.specialities,
        ...person.nationality,
        person.gender,
        person.isAlive && "Alive",
    ].includes(filter)) isFiltered = true

    const isSelected = (bubble === person.slug)
    const isReleased = episodesDataUtil.isEpReleased(person.slug)

    let link
    if (isReleased && !isSelected) {
        link = urlUtil.createURL('bio', { slug: person.slug })
    } else if (isSelected) {
        link = urlUtil.createURL('timeline', { view, bubble: undefined, scroll: undefined }, { keepOthers: true })
    } else {
        link = urlUtil.createURL('timeline', { view, bubble: person.slug, scroll: name }, { keepOthers: true })
    }


    const isBubbleAlignedRight = (timelineWidth - width - left) < (BUBBLE_WIDTH / 2 - width / 2) + 10

    // when bubble is smaller than the pill
    const isSmallBubble = width > BUBBLE_WIDTH - 10

    const classes = [
        'timeline-pill',
        isReleased ? 'link' : '',
        isAlive ? 'is-alive' : '',
        isSelected ? 'is-selected' : '',
        isFiltered ? 'is-filtered' : '',
        isSmallBubble ? 'small-bubble' : '',
        filter ? '' : `lvl-${lvl}`,
        zoomLvl.toLowerCase(),
    ].join(' ')

    const nationalityLinks = []
    person.nationality.forEach((n, i) => {
        if (i !== 0) {
            nationalityLinks.push(<span key={i}>  |  </span>)
        }
        nationalityLinks.push(
            <Link
                key={n}
                to={urlUtil.createURL('timeline', { view, filter: n, scroll: name }, { keepOthers: true })}
            >
                {n}
            </Link>
        )
    })
    const specialityLinks = []
    person.specialities.forEach((m, i) => {
        if (i !== 0) {
            specialityLinks.push(<span key={i}>  |  </span>)
        }
        specialityLinks.push(
            <Link
                key={m}
                to={urlUtil.createURL('timeline', { view, filter: m, scroll: name }, { keepOthers: true })}
            >
                {m}
            </Link>
        )
    })

    const style = {
        width,
        height: pillHeight,
        borderRadius: pillHeight/2
    }
    let textStyles = zoom > 2.5 ? { fontSize: 14 * 2.5 / zoom } : {}
    textStyles = zoom < 0.5 ? { opacity: 0 } : textStyles
    const showHead = episodesDataUtil.isEpReleased(person.slug) && zoom > 2.5
    const showBubble = isSelected && zoom > 0.5 && zoom < 2.5
    let bubbleLeft
    if (isSelected && isBubbleAlignedRight) {
        bubbleLeft = (width - 3 - BUBBLE_WIDTH) + (BUBBLE_WIDTH - BUBBLE_WIDTH / zoom) / 2
    }

    const timelines = episodesDataUtil.getTimelines()
    let specialityIcon = "bolt"
    if (view === timelines.art || person.episodes.indexOf(timelines.art) !== -1) specialityIcon = "paint-brush"
    if (view === timelines.science || person.episodes.indexOf(timelines.science) !== -1) specialityIcon = "beaker"
    if (view === timelines.composers || person.episodes.indexOf(timelines.composers) !== -1) specialityIcon = "musical-note"

    return (
        <>
            <div
                onClick={() => {
                    if (!isSelected) navigateTo(link)
                }}
                className={classes}
                style={style}
                id={name}
            >
                <div className="pill-content">
                    { showHead && <MonsterHead slug={person.slug} size={15} />}
                    <Text style={textStyles} ellipsis className="pill-text">{ !showBubble && name }</Text>
                </div>

                {/* TODO move to separate component */}
                { showBubble && (
                    <div
                        style={{ transform: `scale(${ 1 / zoom })`, left: bubbleLeft }}
                        className={`bubble ${ isBubbleAlignedRight ? "bubble-right" : '' }`}
                    >
                        <div className="bubble-header">
                            <div className="names">
                                <Text weight={500} className="name">{ name }</Text>
                                <Text weight={500}>({ person.birthYearDisplay } - { person.isAlive ? "" : person.deathYearDisplay })</Text>
                            </div>
                            <Link
                                to={link}
                                className="close-btn"
                            >
                                <SVGIcon name="close" size={16} color="link" />
                            </Link>
                        </div>
                        <div className="bubble-content">
                            <Text>{ person.intro }</Text>
                        </div>
                        <div className="bubble-footer">
                            <div className="footer-links">
                                <SVGIcon color="link" name="flag" size={14} />
                                <Text>{ nationalityLinks }</Text>
                            </div>
                            <div className="footer-links">
                                <SVGIcon color="link" name={specialityIcon} size={14} />
                                <Text>{ specialityLinks }</Text>
                            </div>
                            <div className="footer-links">
                                <SVGIcon color="link" name="academic-cap" size={14} />
                                <Text type="link">
                                    <a
                                        href={person.wikipedia}
                                        target="_blank"
                                    >
                                        Wikipedia
                                    </a>
                                </Text>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

TimelinePill.propTypes = {
    // top,
    // left,
    // width,
    // link,
    // text,
};

export default generalUtil.withNavParams(TimelinePill)