import urlUtil from "./urlUtil";
import {camelCase, capitalCase, noCase} from "change-case";
import {ENVS} from "../constants";
const OG_IMAGES = {
    "curiosity-monster": require("../assets/images/og/og-curiosity-monster.jpg"),
    "art-history": require("../assets/images/og/og-art-history.jpg"),

    "frida-kahlo": require("../assets/images/og/og-frida-kahlo.jpg"),
    "gustav-klimt": require("../assets/images/og/og-gustav-klimt.jpg"),
    "leonardo-da-vinci": require("../assets/images/og/og-leonardo-da-vinci.jpg"),
    "michelangelo": require("../assets/images/og/og-michelangelo.jpg"),
    "hieronymus-bosch": require("../assets/images/og/og-hieronymus-bosch.jpg"),
    "peter-paul-rubens": require("../assets/images/og/og-peter-paul-rubens.jpg"),
    "rembrandt-van-rijn": require("../assets/images/og/og-rembrandt-van-rijn.jpg"),
    "johannes-vermeer": require("../assets/images/og/og-johannes-vermeer.jpg"),
    "caravaggio": require("../assets/images/og/og-caravaggio.jpg"),
    "francisco-goya": require("../assets/images/og/og-francisco-goya.jpg"),
    "katsushika-hokusai": require("../assets/images/og/og-katsushika-hokusai.jpg"),
    "utagawa-hiroshige": require("../assets/images/og/og-utagawa-hiroshige.jpg"),
    "claude-monet": require("../assets/images/og/og-claude-monet.jpg"),
    "paul-cezanne": require("../assets/images/og/og-paul-cezanne.jpg"),
    "edvard-munch": require("../assets/images/og/og-edvard-munch.jpg"),
    "vincent-van-gogh": require("../assets/images/og/og-vincent-van-gogh.jpg"),
    "tamara-de-lempicka": require("../assets/images/og/og-tamara-de-lempicka.png"),
    "yayoi-kusama": require("../assets/images/og/og-yayoi-kusama.png"),

    "science-history": require("../assets/images/og/og-science-history.png"),
    "composers-timeline": require("../assets/images/og/og-composers.png"),

    "serbs": require("../assets/images/og/og-curiosity-monster.jpg"),
    "germans": require("../assets/images/og/og-curiosity-monster.jpg"),
    "french": require("../assets/images/og/og-curiosity-monster.jpg"),
    "italians": require("../assets/images/og/og-curiosity-monster.jpg"),
}

const isProduction = true || process.env === ENVS.PRODUCTION

const metaUtil = {
    getOGImage: (ogImage) => {
        return OG_IMAGES[ogImage] || OG_IMAGES["curiosity-monster"]
    },

    getIndexKeywords: () => {
        return [
            "curiosity monster",
            "interactive infographics",
            "interactive visualizations",
            "visual encyclopedia",
            "mobile-friendly infographics",
            "data visualization",
            "interactive learning",
        ]
    },

    getMetaForHome: () => {
        return {
            title: "Curiosity Monster",
            description: "Embark on serendipitous adventures through stunning, engaging and interactive visualizations with Curiosity Monster.",
            image: OG_IMAGES["curiosity-monster"],
            keywords: isProduction ? metaUtil.getIndexKeywords().join(",") : "",
        }
    },
}

export default metaUtil