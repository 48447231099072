import browserInfoUtil from "./browserInfoUtil";
import peopleDataUtil from "./peopleDataUtil"
import {TIMELINE_FILTER_SCROLLS} from "../constants";
import DB from "../data/DB.json"

const { SCROLLS } = DB

// TODO rename to ZOOM_BREAKPOINTS
const ZOOMS = {
    XL: "XL",
    M: "M",
    XS: "XS",
}

function isBetween(number, low, high) {
    return number > low && number < high
}

const timelineUtil = {
    DIMS: {
        ROW_HEIGHT: 25,
        PILL_HEIGHT: 19,
        SCALE: 2, // year to pixel ratio 2px = 1y
    },

    getZoomLvl: (store) => {
        const zoom = store.transform[2]

        if (zoom > 3.5) return 7    // 4XL
        if (zoom > 3) return 6      // 3XL
        if (zoom > 2.5) return 5    // XXL
        if (zoom > 2) return 4      // XL
        if (zoom > 1.5) return 3    // L
        if (zoom > 1) return 2      // M
        if (zoom > 0.5) return 1    // S
        return 0                    // XS
    },

    canFitIntoSlot: (person, slot) => {
        const slotEnd = person.death === new Date().getFullYear() ? slot.end : slot.end - 5
        return isBetween(Number(person.birth), slot.start + 5, slotEnd) && isBetween(person.death, slot.start, slotEnd)
    },

    getTopForRow: (rowIndex, numOfRows) => {
        if (rowIndex === -1) return -120
        const { ROW_HEIGHT, PILL_HEIGHT } = timelineUtil.DIMS

        const timelineHeight = timelineUtil.calculateHeight(numOfRows)
        let top
        if (rowIndex % 2 === 0) {
            top = (timelineHeight / 2) - (PILL_HEIGHT / 2) + (rowIndex / 2) * ROW_HEIGHT
        } else {
            top = (timelineHeight / 2) - (PILL_HEIGHT / 2) - ((rowIndex + 1) / 2) * ROW_HEIGHT
        }

        return top
    },

    calculateHeight: (numOfRows) => {
        const { ROW_HEIGHT } = timelineUtil.DIMS
        return numOfRows * ROW_HEIGHT + 300
    },

    calculatePositions: (people, startYear, endYear) => {
        const { SCALE } = timelineUtil.DIMS
        const dataWithPositions = []
        const verticalPositionIndex = []

        // to show all data set to 500
        for (let i = 0; i <= 200; i++) {
            verticalPositionIndex[i] = [{ start: startYear, end: endYear}]
        }

        people.forEach(person => {
            person.birth = Number(person.birthYear)
            if (!person.birth || person.birth < startYear) return;
            if (!person.deathYear) {
                person.death = new Date().getFullYear()
            } else {
                person.death = Number(person.deathYear)
            }

            let rowIndex = -1
            verticalPositionIndex.every((row, i) => {
                row.every((slot, j) => {
                    if (timelineUtil.canFitIntoSlot(person, slot)) {
                        rowIndex = i
                        row[j] = { start: slot.start, end: Number(person.birth)}
                        row.splice(j + 1, 0, { start: person.death, end: slot.end })
                        return false
                    }
                    return true
                })


                return (rowIndex === -1)
            })

            const death = Number(person.death) || new Date().getFullYear()

            const personWithPosition = {
                ...person,

                top: timelineUtil.getTopForRow(rowIndex),
                left: (person.birth - startYear) * SCALE,
                width: (death - person.birth) * SCALE,
                rowIndex,
            }


            dataWithPositions.push(personWithPosition)
        })

        const numOfRows = verticalPositionIndex.find(el => el.length === 1)
            ? verticalPositionIndex.indexOf(verticalPositionIndex.find(el => el.length === 1))
            : verticalPositionIndex.length

        dataWithPositions.forEach(person => person.top = timelineUtil.getTopForRow(person.rowIndex, numOfRows))
        return { dataWithPositions, numOfRows }
    },

    getSize: (start, end, numOfRows) => {
        const { SCALE } = timelineUtil.DIMS
        return {
            timelineWidth: (end - start) * SCALE,
            timelineHeight: timelineUtil.calculateHeight(numOfRows),
        }
    },

    calcViewport: (node, zoom) => {
        const posXMax = 0
        const posXMin = (- node.data.timelineWidth) * zoom + browserInfoUtil.getWindowSize().width
        let posX = (-node.position.x - node.data.person.width / 2) * zoom + browserInfoUtil.getWindowSize().width / 2
        posX = Math.min(posXMax, posX)
        posX = Math.max(posXMin, posX)

        const posY = (-node.position.y - timelineUtil.DIMS.PILL_HEIGHT / 2) * zoom + browserInfoUtil.getWindowSize().height / 2

        return {
            x: posX,
            y: posY,
            zoom,
        }
    },

    getViewport: (store) => {
        const viewport = {
            x: store.transform[0],
            y: store.transform[1],
            zoom: store.transform[2]
        }

        return viewport
    },

    getScrollToForTimeline(view, filter) {
        const epFilters = SCROLLS.filter(s => s.epSlug === view)

        if (epFilters.length) {
            const fil = epFilters.filter(f => f.filter === filter)
            if (fil.length) return fil[0].scroll
        }

        return peopleDataUtil.sortBy({ episode: view, [timelineUtil.getFilterType(filter)]: filter })[0].name
    },

    getFilterType: (filterValue) => {
        const nationalities = peopleDataUtil.getAllNationalities()
        const specialities = peopleDataUtil.getAllSpecialities()

        if (nationalities.indexOf(filterValue) >= 0) return 'nationality'
        if (specialities.indexOf(filterValue) >= 0) return 'speciality'
        if (['Male', 'Female'].indexOf(filterValue) >= 0) return 'gender'
        if (['Alive'].indexOf(filterValue) >= 0) return 'other'
    },
}

export default timelineUtil