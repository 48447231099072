import DB from "../data/DB.json"
import {capitalCase} from "change-case";

const eventsJSON = DB.EVENTS
let eventsData

const eventsDataUtil = {
    getBySlug: (slug) => {
        eventsDataUtil.parseData()
        return eventsData.filter(p => p.slug === slug)
    },

    getForBioBySlug: (slug) => {
        const events = eventsDataUtil.getBySlug(slug)
        const uniqueYears = events
            .map(e => e.year)
            .filter((e, i, arr) => arr.indexOf(e) === i)
            .sort()

        const bioEvents = uniqueYears.map(y => {
            const yearEvents = events.filter(e => e.year === y)
            const age = yearEvents[0].age
            return { year: y, age, events: yearEvents }
        })

        return bioEvents
    },

    getByFilter: (slug, filter) => {
        const bioEvents = eventsDataUtil.getForBioBySlug(slug)
        const filterEvents = bioEvents.map(year => {
            const eventsYear = { ...year }
            eventsYear.events = eventsYear.events.filter(e => e.category === capitalCase(filter) || e.category === filter)
            return eventsYear
        }).filter(year => year.events.length > 0)

        return filterEvents
    },

    parseYearGridData: function (categories, eventsByYear) {
        const yearGridData = {}

        eventsByYear.forEach(yd => {
            yearGridData[yd.year] = { cat1: 0, cat2: 0, cat3: 0, cat4: 0, cat5: 0, cat6: 0, cat7: 0 }
            yd.events.forEach(e => yearGridData[yd.year][categories[e.category]]++)
        })

        return yearGridData
    },

    getNetworkedEvent: (year) => {
        return eventsData
            .filter(e => e.isNetworked && e.year == year)[0]
    },

    parseData: () => {
        if (eventsData) return;
        eventsData = eventsJSON.filter(e => e.isVerified)
    }
}

export default eventsDataUtil