import React from 'react';
import {Link} from "react-router-dom";
import Text from "../../atoms/Text/Text";

import "./Home.scss";

import {ENVS, WALLETS} from "../../../constants";
import SVGIcon from "../../atoms/SVGIcon/SVGIcon";
import urlUtil from "../../../utils/urlUtil";
import randomQuestionsUtil from "../../../utils/randomQuestionsUtil";
import Page from "../../atoms/Page/Page";
import {MONSTERS} from "../../../utils/monsterImagesUtil";
import episodesDataUtil from "../../../utils/episodesDataUtil";
import NewestEpisode from "../../molecules/NewestEpisode/NewestEpisode";
import NextEpisode from "../../atoms/NextEpisode/NextEpisode";
import ReleasedEpisode from "../../molecules/ReleasedEpisode/ReleasedEpisode";
import RandomLink from "../../atoms/RandomLink/RandomLink";
import WalletAddress from "../../atoms/WalletAddress/WalletAddress";
import ThemePicker from "../../molecules/ThemePicker/ThemePicker";
import {Helmet} from "react-helmet";
import metaUtil from "../../../utils/metaUtil";
import MonsterCircle from "../../atoms/MonsterCircle/MonsterCircle";

const monsterImages = [
    MONSTERS.curiosityMonster1,
    MONSTERS.curiosityMonster2,
    MONSTERS.curiosityMonster3,
    MONSTERS.curiosityMonster4,
    MONSTERS.curiosityMonster5,
    MONSTERS.curiosityMonster6,
    MONSTERS.curiosityMonster7,
    MONSTERS.curiosityMonster8,
]
const randomMonster = monsterImages[ new Date().getTime() % monsterImages.length ]

async function share() {
    const shareData = {
        url: urlUtil.getCurrentURL(),
        text: metaUtil.getMetaForHome().description,
        title: metaUtil.getMetaForHome().title,
    }
    try {
        await navigator.share(shareData);
    } catch (err) {
        console.log(err)
    }
}

function ComingSoon() {
    const nextEps = episodesDataUtil.getNextEpisodes()
    const nextEpisodes = nextEps.map(ep => <NextEpisode episode={ep} />)
    const metaInfo = metaUtil.getMetaForHome()

    return (
        <Page classes="home-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ metaInfo.title }</title>
                <meta name="description" content={  metaInfo.description } />
                <meta name="keywords" content={ metaInfo.keywords } />
                <meta name="og:image" content={ metaInfo.image } />
            </Helmet>

            <div className="what section">
                <img src={monsterImages[ new Date().getTime() % monsterImages.length ]} className="hero-image" alt="hero"/>
                <Text type="hero" align="center" uppercase>Curiosity Monster</Text>
                <Text align="center">
                    Let your curiosity take the wheel and discover the beauty of knowledge with our visually-driven explorations.
                </Text>

            </div>

            <div className="section">
                <Text type="hero" align="center" uppercase>Coming Soon...</Text>
                { nextEpisodes }
            </div>

            <Text align="center">&copy; { new Date().getFullYear() }, Curiosity Monster. All rights reserved.</Text>
        </Page>
    )
}

function Home() {
    const newestEps = episodesDataUtil.getNewestEpisodes()
    const newestEpisodes = newestEps.map(ep => <NewestEpisode episode={ep} />)

    const nextEps = episodesDataUtil.getNextEpisodes()
    const nextEpisodes = nextEps.map(ep => <NewestEpisode episode={ep} clickable={false} />)

    const releasedEps = episodesDataUtil.getReleasedForSeason().slice(1)
    const releasedEpisodes = releasedEps.map(ep => <ReleasedEpisode episode={ep} />)

    const randomLinks = randomQuestionsUtil.getQuestions().map(rl => <RandomLink link={rl} />)

    const wallets = Object.keys(WALLETS).map(w => <WalletAddress coin={w} />)

    if (process.env.REACT_APP_ENV === ENVS.PRODUCTION && !episodesDataUtil.getReleasedForSeason().length) return <ComingSoon />

    const metaInfo = metaUtil.getMetaForHome()

    return (
        <Page classes="home-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ metaInfo.title }</title>
                <meta name="description" content={  metaInfo.description } />
                <meta name="keywords" content={ metaInfo.keywords } />
                <meta name="og:image" content={ metaInfo.image } />
            </Helmet>

            <div className="what section">
                <div onClick={async () => await share()} className="share-btn">
                    <SVGIcon
                        name="share"
                        size={18}
                        color="link"
                    />
                </div>
                <img src={ randomMonster } className="hero-image" alt="hero"/>
                <Text type="hero" align="center" uppercase>Curiosity Monster</Text>
                <Text align="center">
                    Let your curiosity take the wheel and discover the beauty of knowledge with our visually-driven explorations.
                </Text>
            </div>

            <div className="section">
                <div className="icon-h2">
                    <SVGIcon name="bell-alert" size={14} />
                    <Text type="h2">Newest Episodes</Text>
                </div>
                { newestEpisodes }
            </div>

            { nextEpisodes.length ? (
                <div className="section">
                    <div className="icon-h2">
                        <SVGIcon name="calendar-days" size={14} />
                        <Text type="h2">Coming up next</Text>
                    </div>
                    { nextEpisodes }
                </div>
            ) : ""}

            <div
                className="dark-section timeline-section section"
            >
                <Link
                    className="content"
                    to={urlUtil.createURL("timeline", { view: "art" })}
                >
                    <div className="heading">
                        <MonsterCircle src={episodesDataUtil.getEpByParam("art").imageLink} size={120} color="card" />
                        <Text>
                            { episodesDataUtil.getEpByParam("art").description }
                        </Text>
                    </div>
                    <div className="heading">
                        <Text type="h1" align="left">View Art Timeline</Text>
                        <SVGIcon name="arrow-right" size={28} />
                    </div>
                </Link>
            </div>

            <div className="section">
                <div className="icon-h2">
                    <SVGIcon name="paint-brush" size={14} />
                    <Text type="h2">Most Iconic Painters</Text>
                </div>
                <div
                    className="released-wrapper"
                    style={{ justifyContent: releasedEpisodes.length > 2 ? null : "center" }}>
                    { releasedEpisodes }
                </div>
            </div>
            <div className="random-links section">
                <Text type="h1">Ignite your Curiosity</Text>
                <Text>
                    Start exploring Curiosity Monster through some of our curated journeys.
                </Text>
                <div className="random-links-wrapper">
                    { randomLinks }
                </div>
            </div>

            <div className="section theme-section">
                <ThemePicker />
            </div>

            <div className="about section">
                <Text type="h1">About Curiosity Monster</Text>
                <Text>
                    At our studio, we believe learning should be a joy. We craft vibrant, interactive content to inspire those unexpected "wow" moments—think of us as your adventure partners, with a creative twist!
                </Text>
            </div>

            <div className="dark-section section">
                <div className="content">
                    <div className="heading">
                        <Text type="h1">
                            Show us some love
                        </Text>
                        <SVGIcon name="heart" size={24} />
                    </div>
                    <Text>
                        Join us in our mission to inspire and simplify the world by donating to Curiosity Monster, a small but mighty team creating beautiful and interactive content.
                    </Text>
                    <div className="wallets-wrapper">
                        { wallets }
                    </div>
                </div>
            </div>

            <Text align="center">&copy; { new Date().getFullYear() }, Curiosity Monster. All rights reserved.</Text>
        </Page>
    );
}

export default Home