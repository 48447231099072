import DB from "../data/DB.json"
import { camelCase } from "change-case";
import {MONSTERS} from "./monsterImagesUtil";

const peopleJSON = DB.PEOPLE
const categoriesJSON = DB.CATEGORIES

let peopleData

const peopleDataUtil = {
    getAllNationalities: () => DB.NATIONALITIES,
    getAllSpecialities: () => DB.SPECIALITIES,

    getNationalities: (people) => {
        let nationalities = []
        people.forEach(p => {
            p.nationality.forEach(n => {
                if (nationalities.indexOf(n) === -1) nationalities.push(n)
            })
        })
        return nationalities
    },
    getSpecialities: (people) => {
        let specialities = []
        people.forEach(p => {
            p.specialities.forEach(s => {
                if (specialities.indexOf(s) === -1) specialities.push(s)
            })
        })
        return specialities
    },

    getAll: () => {
        peopleDataUtil.parsePeople()
        return peopleData;
    },

    getForTimeline: (episode) => {
        peopleDataUtil.parsePeople()
        return peopleData.filter(p => p.episodes && p.episodes.indexOf(episode) !== -1);
    },

    getBySlug: (slug) => {
        peopleDataUtil.parsePeople()
        return peopleData.find(p => p.slug === slug)
    },

    parsePeople: () => {
        if (peopleData) return;
        peopleData = peopleJSON
            .filter(raw => !!raw.birthYear && raw.isValid)
            .map(raw => {
                const person = { ...raw }

                // map categories / needs refactoring throughout the app
                person.categories = {}
                if (person.hasBio) {
                    const personCats = categoriesJSON.filter(c => c.slug === person.slug)
                    personCats.forEach(cat => {
                        person.categories[cat.category] = `cat${cat.pos}`
                        person[`cat${cat.pos}Text`] = cat.text
                    })
                }

                person.birthYearDisplay = person.birthYear > 0 ? person.birthYear : `${- person.birthYear} BC`
                person.deathYearDisplay = person.deathYear > 0 ? person.deathYear : `${- person.deathYear} BC`

                // set avatar image
                person.avatar = MONSTERS[camelCase(person.slug)]

                person.specialities = person.specialities.split(",").map(s => s.trim())
                person.nationality = person.nationality.split(",").map(n => n.trim())
                person.episodes = person.episodes.split(",").map(e => e.trim())

                return person
            })
    },

    sortByBirthDate: (people) => people.sort((a, b) => a.birthYear - b.birthYear),

    sortByLVL: (people) => {
        const lvlArrays = { hasBio: [], lvl5: [], lvl4: [], lvl3: [], rest: [] };

        people.forEach(p => {
            if (p.hasBio) lvlArrays.hasBio.push(p)
            else if (p.lvl === 5 && !p.hasBio) lvlArrays.lvl5.push(p)
            else if (p.lvl === 4 && !p.hasBio) lvlArrays.lvl4.push(p)
            else if (p.lvl === 3 && !p.hasBio) lvlArrays.lvl3.push(p)
            else lvlArrays.rest.push(p)
        })

        return [
            ...peopleDataUtil.sortByBirthDate(lvlArrays.hasBio),
            ...peopleDataUtil.sortByBirthDate(lvlArrays.lvl5),
            ...peopleDataUtil.sortByBirthDate(lvlArrays.lvl4),
            ...peopleDataUtil.sortByBirthDate(lvlArrays.lvl3),
            ...peopleDataUtil.sortByBirthDate(lvlArrays.rest),
        ]
    },

    sortBy: (sortParams) => {
        const { episode, speciality, nationality, gender, other } = sortParams
        let filteredPeople = []
        let rest = []
        const episodePeople = peopleDataUtil.getForTimeline(episode)

        episodePeople.forEach(p => {
            if (speciality && p.specialities.includes(speciality)) {
                filteredPeople.push(p)
            } else if (nationality && p.nationality.includes(nationality)) {
                filteredPeople.push(p)
            } else if (gender && p.gender === gender) {
                filteredPeople.push(p)
            } else if (other && other === "Alive" && p.isAlive) {
                filteredPeople.push(p)
            } else {
                rest.push(p)
            }
        })

        filteredPeople = peopleDataUtil.sortByLVL(filteredPeople)
        rest = peopleDataUtil.sortByLVL(rest)

        return [
            ...filteredPeople,
            ...rest
        ]
    }
}

export default peopleDataUtil