import React, { useState } from "react";

import "./TimelineFilter.scss"
import SVGIcon from "../../atoms/SVGIcon/SVGIcon";
import SideMenu from "../SideMenu/SideMenu";
import Text from "../../atoms/Text/Text";
import peopleDataUtil from "../../../utils/peopleDataUtil";
import urlUtil from "../../../utils/urlUtil";
import timelineUtil from "../../../utils/timelineUtil";
import {Link} from "react-router-dom";
import MonsterHead from "../../atoms/MonsterHead/MonsterHead";
import DB from "../../../data/DB.json"
import metaUtil from "../../../utils/metaUtil";
import episodesDataUtil from "../../../utils/episodesDataUtil";

const { SCROLLS } = DB

async function share(shareData) {
    try {
        await navigator.share(shareData);
    } catch (err) {
        console.log(err)
    }
}

const TimelineFilter = (props) => {
    const { data, episode } = props
    const { filter, view } = urlUtil.getURLParams()
    const [isMenuOpen, setIsMenuOpen] = useState();
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState();
    const [filterText, setFilterText] = useState("");

    const timelines = episodesDataUtil.getTimelines()

    const renderSearchItem = ({ item, type }) => {
        const isPerson = item && !!item.slug

        const url = isPerson
            ? urlUtil.createURL('timeline', { view, scroll: item.name, bubble: item.slug })
            : urlUtil.createURL('timeline', { view, filter: item, scroll: timelineUtil.getScrollToForTimeline(view, item) })
        const caption = isPerson
            ? item.name
            : item


        let label = type
        if (type === "speciality") {
            label = view === timelines.art ? "art movement" : label
            label = view === timelines.science ? "field of study" : label
        }
        if (type === "person") {
            label = view === timelines.art ? "artist" : label
            label = view === timelines.science ? "scientist" : label
        }

        return (
            <Link
                key={caption}
                onClick={() => {
                    setIsFilterMenuOpen(false)
                    setFilterText("")
                }}
                to={url}
                className="filter-item"
            >
                <Text>{ caption }</Text>
                <Text type="small" uppercase alt className={type}>{ label }</Text>
            </Link>
        )
    }

    const specialities = peopleDataUtil
        .getSpecialities(data)
        .filter(m => m && m.toLowerCase().includes(filterText.toLowerCase()))
        .map(m => renderSearchItem({ item: m, type: "speciality"}))

    const nationalities = peopleDataUtil
        .getNationalities(data)
        .filter(n => n && n.toLowerCase().includes(filterText.toLowerCase()))
        .map(n => renderSearchItem({ item: n, type: "nationality"}))

    const people = data
        .filter(p => p && p.name.toLowerCase().includes(filterText.toLowerCase()))
        .map(p => renderSearchItem({ item: p, type: "person"}))

    const other = ['Alive', 'Female', 'Male']
        .filter(f => f && f.toLowerCase().includes(filterText.toLowerCase()))
        .map(f => renderSearchItem({ item: f, type: "other"}))

    const epFilters = SCROLLS.filter(s => s.epSlug === view)
    const randomFilters = epFilters.length ? epFilters
        .map(filter => ({ filter, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(el => el.filter)
        .slice(0, 7)
        .map(filter => renderSearchItem({ item: filter.filter, type: filter.type }))
        : []


    const filteredItems = filterText ? [
        ...specialities,
        ...nationalities,
        ...people,
        ...other,
    ] : randomFilters

    let placeholder
    let placeholderClass = ""
    if (filter && !isFilterMenuOpen) {
        placeholder = filter
        placeholderClass = "filter-active"
    } else if (!filterText && isFilterMenuOpen) {
        placeholder = `Explore ${view} timeline`
        placeholderClass = "focused"
    } else {
        placeholder = `Explore ${view} timeline`
        placeholderClass = "idle"
    }

    filteredItems.sort(function(a, b) {
        var textA = a.key.toUpperCase();
        var textB = b.key.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    const shareData = {
        url: urlUtil.getCurrentURL(),
        title: episode.title,
        text: episode.description,
    }

    return (
        <div className="timeline-filter">
            <div className="filter-input-wrapper">
                <div className="side">

                    { filter ? (
                        <MonsterHead slug={view} size={36} color="cat-3" />
                    ) : (
                        <MonsterHead slug={view} size={36} color="cat-2" />
                    )}

                    <input
                        className={`filter-input ${ placeholderClass }`}
                        value={filterText}
                        onChange={(e) => {
                            setFilterText(e.target.value)
                        }}
                        onFocus={() => setTimeout(() => setIsFilterMenuOpen(true),0)}
                        onBlur={() => setTimeout(() => setIsFilterMenuOpen(false),0)}
                        placeholder={placeholder}
                    />
                </div>

                <div className="right side">
                    { filter && (
                        <Link
                            onClick={() => setIsFilterMenuOpen(false)}
                            to={urlUtil.createURL('timeline', { view })}
                        >
                            <SVGIcon
                                name="close"
                                size={24}
                                color="link"
                            />
                        </Link>
                    )}

                    <div onClick={async () => await share(shareData)}>
                        <SVGIcon
                            name="share"
                            size={18}
                            color="link"
                        />
                    </div>

                    <div onClick={() => setIsMenuOpen(true)}>
                        <SVGIcon
                            name="hamburger"
                            size={24}
                            color="link"
                        />
                    </div>
                </div>

            </div>

            { isFilterMenuOpen && (
                <div className="filter-menu">
                    { filteredItems.length > 0 ? filteredItems : (
                        <div
                            className="filter-item"
                        >
                            <Text>Oooops... Nothing matches your search.</Text>
                        </div>
                    )}
                </div>
            )}

            <SideMenu
                isMenuOpen={isMenuOpen}
                toggleMenu={() => setIsMenuOpen(false)}
            />
        </div>
    )
};

export default TimelineFilter;
