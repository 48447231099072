import DB from "../data/DB.json"
import urlUtil from "./urlUtil";
import {MONSTERS} from "./monsterImagesUtil";
import {camelCase} from "change-case";

const {REACT_APP_ENV} = process.env
const episodesJSON = DB.EPISODES
let episodesData

const episodesDataUtil = {
    getAll: () => {
        if (episodesData) return episodesData
        episodesDataUtil.parseData()
        return episodesData
    },

    getEpByParam: (param) => {
        return episodesDataUtil.getAll().filter(ep => ep.param === param)[0]
    },

    getNewestEpisodes: () => {
        return episodesDataUtil.getAll().filter(ep => ep.isNewest)
    },

    getReleasedForSeason: (season = "Art") => {
        return episodesDataUtil.getAll().filter(ep => (ep.season.toLowerCase() === season.toLowerCase() || ep.sID == season) && ep.isReleased)
    },

    getReleased: () => {
        return episodesDataUtil.getAll().filter(ep => ep.isReleased)
    },

    isEpReleased: (slug) => {
        return !!episodesDataUtil.getReleased().find(ep => ep.param === slug)
    },

    getTimelines: () => {
        const timelineEps = episodesDataUtil.getAll().filter(ep => ep.page === "timeline")

        const timelines = {}
        timelineEps.forEach(ep => timelines[ep.param] = ep.param)
        return timelines
    },

    getReleasedEpisodeIds: () => {
        return episodesDataUtil.getAll().filter(ep => ep.isReleased).map(ep => ep.id)
    },

    getReleasedEpisodeSlugs: () => {
        return episodesDataUtil.getAll().filter(ep => ep.isReleased).map(ep => ep.slug)
    },

    getNextEpisodes: () => {
        return episodesDataUtil.getAll().filter(ep => ep.isNext)
    },

    parseData: () => {
        if (episodesData) return;
        let newestEp
        let nextEp

        episodesData = episodesJSON
            .filter(e => e.id && e.name)
            .map(e => {
                let rDate;
                if (REACT_APP_ENV && REACT_APP_ENV === "PRODUCTION") {
                    rDate = e.rDateProd ? new Date(e.rDateProd) : null
                } else {
                    rDate = e.rDateStag ? new Date(e.rDateStag) : null
                }
                return {
                    ...e,
                    rDate,
                }
            })
            .sort((a, b) => {
                const dateA = new Date(a.rDate);
                const dateB = new Date(b.rDate);

                // Check if either date is null or invalid
                if (!a.rDate) return 1; // Keep `a` at the bottom if date is null
                if (!b.rDate) return -1; // Keep `b` at the bottom if date is null

                return dateA - dateB; // Sort normally if both dates are valid
            })
            .map(e => {
                const ep = { ...e }

                ep.url = urlUtil.createURL(ep.page, { slug: ep.param })
                if (ep.page === "timeline") {
                    ep.url = urlUtil.createURL(ep.page, { view: ep.param })
                }

                ep.imageLink = MONSTERS[ep.imageKey || camelCase(ep.param)]

                ep.isReleased = !!ep.rDate && ep.rDate.getTime() < new Date().getTime()

                if (ep.isReleased) {
                    newestEp = ep.id.slice(0, 6)
                } else if (!nextEp) {
                    nextEp = ep.id.slice(0, 6)
                }
                return ep
            }).map(e => {
                const ep = {
                    ...e,
                    isNext: !!(e.id.includes(nextEp) && e.rDate),
                    isNewest: e.id.includes(newestEp),
                }

                return ep
            })
    }
}

export default episodesDataUtil